import { Container } from "components";
import React, { useEffect, useState } from "react";
import api from "services/api";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Pagination from "components/pagination";

export const CorperatorsList = () => {
  const navigate = useNavigate();
  const [corperators, setCorperators] = useState([]);
  const [showCorperators , setShowCorperators ] = useState(false);
  const [currentPage , SetCurrentPage ] = useState(1);

  const fetchCorperate = async (page=1) => {
    try {
      const response = await api.get(`/users?accountType=Corporate&page=${page}`);
      setCorperators(response?.data?.data);
      SetCurrentPage(page)
      console.log("fetchCorperate:", response.data.data);
    } catch (error) {
      console.log(" fetchCorperate Error:", error);
    }
  };

  useEffect(() => {
    fetchCorperate();
  }, []);
  console.log("corperators:",)
  return (
    <Container>
      <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y" style={{height:"43px"}}>
          <ul className="nav nav-pills flex-column flex-md-row mb-4">
            <li className="nav-item">
              <h4 className={`py-3 mb-4`}>
                {!showCorperators ? "Corperaters " : "Corperaters Details"}
              </h4>
            </li>
          </ul>
          <div class="card-datatable table-responsive">
            <table class="invoice-list-table table border-top dataTable no-footer dtr-column mb-5">
              <thead>
                <tr>
                  <th>Sno</th>
                  <th>Profile</th>
                  <th>Name</th>
                  <th>email</th>

                  <th class="text-truncate">Registred ON</th>
                  <th class="text-truncate">KYC </th>
                  <th class="cell-fit">Phone No</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {corperators?.users?.map((res, index) => {
                   const serialNumber = (currentPage - 1) * corperators?.pagination?.per_page + (index + 1);
                  return (
                    <tr>
                      <td>{serialNumber}</td>
                      <td>
                        <img
                          src={res.profile.profile_pic_url}
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                          }}
                        />
                      </td>
                      <td>
                        {res.first_name} {res.middle_name} {res.last_name}
                      </td>
                      <td>{res.email}</td>
                      <td>
                        {moment(res.profile.created_at).format("DD-MM-YYYY")}
                      </td>
                      <td>
                        <span
                          className={`badge ${
                            res.profile.kyc_status === "Pending"
                              ? "bg-warning"
                              : res.profile.kyc_status === "Approved"
                              ? "bg-success"
                              : "bg-danger"
                          }`}
                        >
                          {res.profile.kyc_status}
                        </span>
                      </td>
                      <td>{res.profile.phone_no}</td>
                      <td>
                        <button
                          variant="outlined"
                          className="btn btn-primary"
                          onClick={() =>
                            navigate(`/corperatorsDetails/${res.user_id}`)
                          }
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {corperators?.pagination?.total > 0 &&<Pagination totalPages={(corperators?.pagination?.total)} perPage={corperators?.pagination?.per_page} handleFunction={fetchCorperate}/>}  
          </div>
        </div>
        {/* <Footer /> */}
        <div class="content-backdrop fade"></div>
      </div>
    </Container>
  );
};
