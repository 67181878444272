import axios from "axios";
import { BASE_URL } from "./api.config";

const api = axios.create({
  baseURL: BASE_URL,
  timeout: 20000,
});
// 2255|vTtr5BYlXVzxeK2IS3giPkmtnSy7K7xlHgCx1FNB
// Add request interceptor to attach Authorization header
api.interceptors.request.use(
  (config) => {
    const tokenString = localStorage.getItem("user");
    const userToken = tokenString ?  JSON.parse(tokenString) :"";
    if (userToken?.token) {
      config.headers.Authorization = "Bearer " + userToken?.token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
