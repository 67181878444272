import { Container } from "components";
import { useToaster } from "hooks";
import JoditEditor from "jodit-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "services/api";
import { Privacy_Terms } from "services/api.config";

export const PrivacyPolicy = () => {
  const [fetchPrivacyData, setFetchPrivacyData ] = useState("")
  const navigate = useNavigate();
  const {setSuccessToaster , setErrorToaster} = useToaster();
  const fetchPrivacyPolicy = async () => {
    try {
      const response = await api.get(`/${Privacy_Terms}`);
      const privacyData = response?.data?.data?.siteSettings;
      
      setFetchPrivacyData(privacyData?.privacy_policy || ""); // Initialize editor content
      console.log("fetchPrivacyPolicy:", privacyData);
    } catch (error) {
      console.log("fetchPrivacyPolicy Error:", error);
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await api.post(`/${Privacy_Terms}`, {
        privacy_policy: fetchPrivacyData,
      });
      // navigate('/dashboard')
      setSuccessToaster("Privacy Policy Succesfully Updated")
      console.log("handleSubmit Response:", response);
    } catch (error) {
      let message = error?.response?.data?.message ;
      setErrorToaster(message)
      console.log("handleSubmit Error:", message);
    }
  };

  useEffect(() => {
    fetchPrivacyPolicy();
  }, []);
  return (
    <Container>
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row">
            <div className="col-12">
              <div className="card mb-4">
                <JoditEditor
                  value={fetchPrivacyData}
                  onChange={(newContent) => setFetchPrivacyData(newContent)}
                />

                <button
                  type="submit"
                  className="btn btn-primary mt-3"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
