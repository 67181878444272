import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";

import { Login, Home } from "pages";
import { Toaster } from "components";
import UpdateProfile from "pages/updateProfile";
import BillingPlans from "pages/billings_plans";
import MembershipCard from "pages/viewMembershipCard";
import ViewProfile from "pages/home/components/viewProfile";
import MembershipSelection from "pages/billings_plans/components/membershipSelection";
import ApplicationDetails from "pages/billings_plans/components/applicationDetails";
import PaymentResult from "pages/billings_plans/components/payment";
import CheckMembership from "pages/checkMembership/checkMembership";
import { ResetPassword } from "pages/login/resetPassword";
import { ConfirmPassword } from "pages/login/confirmPassword";
import MemebershipsList from "pages/memberships/membershipsList";
import MemebershipsDetails from "pages/memberships/membershipDetails";
import { CorperatorsList } from "pages/corperators/corperatorsList";
import { CorperatorsDetails } from "pages/corperators/corperatorsDetails";
import { TermsConditions ,PrivacyPolicy } from "pages/settings/index";
import { ShowPlansAll } from "pages/plans/showPlansAll";
import { PlansEdit } from "pages/plans/plansEdit";
import { Conformation } from "pages/memberships/conformation";
import { AboutUs } from "pages/settings/aboutUs";
import { AddPlan } from "pages/plans/addPlan";
import { MembershipDetailsCard } from "pages/memberships/membershipDetailsCard";

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route element={<Login />} path="*" />
        <Route element={<Login />} path="/" />
        {/* DashBoard */}
        <Route element={<Home />} path="/dashboard" />
        {/* plans */}
        <Route element={<ShowPlansAll />} path="/showplansall" />
        <Route element={<MembershipSelection />} path="/selectedPlan/:id" />
        <Route element={<PlansEdit />} path="/plansEdit/:id" />
        <Route element={<AddPlan />} path="/addPlan" />
        {/* Memmberships */}
        <Route element={<MemebershipsList />} path="/memberships" />
        <Route element={<MemebershipsDetails />} path="/membershipDetails/:id" />
        <Route element={<Conformation />} path="/conformation"/>
        <Route element={<MembershipDetailsCard />} path="/membershipDetailsCard/:id" />
        {/* Corperaters */}
        <Route element={<CorperatorsList />} path="/corperators" />
        <Route element={<CorperatorsDetails />} path="/corperatorsDetails/:id" />
        {/* Settings */}
        <Route element={<TermsConditions />}  path="/termsConditions"/>
        <Route element={<PrivacyPolicy />} path="/PrivacyPolicy" />
        <Route element={<AboutUs />}  path="/aboutUs"/>
        {/*  */}
        <Route element={<BillingPlans />} path="/billingplans/:id" />
        <Route element={<PaymentResult />} path="/payment/:id" />
        <Route element={<ApplicationDetails />} path="/membershipForm/:id" />
        <Route element={<MembershipCard />} path="/membershipcard" />
        <Route element={<CheckMembership />} path="/checkMembership" />
        <Route element={<ResetPassword />} path="/restPassword" />
        <Route element={<ConfirmPassword />} path="/setPassword/:id" />
      </Routes>
      <Toaster />
    </BrowserRouter>
  );
}

export default App;
